<template>
	<div style="position:relative;">
		<api-data-source :api-path="apiPath" v-slot="apiProp" @loaded="searchCompleted">
			<div class="p-input-icon-left w-full">
				<i class="pi pi-search" />
				<InputText @focus="showResult" v-bind="$attrs" placeholder="Type your name as is in Id" :value="searchText" @input="debounceInput($event)" style="border-radius: 30px;" class="w-full  px-5 py-3 p-button-raised p-rounded white-space-nowrap" size="lg" />
			</div>
			<div  class="search-result-holder">
				<Dialog :closable="false" v-model:visible="show" maximizable modal header="Community Finds" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
				<div class="flex justify-content-between">
					<div class="p-0">
						
						<!--custom code-->
						<div class="flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary " >
							<div class="flex flex-column gap-2 col-12 md:col-12">
								<div class="flex align-items-center gap-2 w-full">
									<h1 class="m-0 font-semibold text-900 text-xl line-height-3"  v-if="totalRecords" >Showing record</h1>
									{{recordsPosition}} of <span class="p-badge p-component p-badge-warning" data-pc-name="badge" data-pc-section="root">{{totalRecords}}</span>
								</div>
								<InputText @focus="showResult" v-bind="$attrs" placeholder="Type your name as is in Id" :value="searchText" @input="debounceInput($event)" style="border-radius: 30px;" class="w-full font-bold px-5 py-3 p-button-raised p-rounded white-space-nowrap" size="lg" />

							</div></div>
						<!--custom code-->
					</div>
					<div>
						<Button @click="hideSearch()" class="p-button-text p-button-sm p-button-danger" icon="pi pi-times" />
					</div>
					
				</div>

				
				<div class="result-list">
					<slot :close="hideSearch" :records="records" :searchText="searchText" :loading="apiProp.loading"></slot>
				</div>
				<Paginator v-if="totalPages>1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
				</Paginator>
			</Dialog>
			</div>
		</api-data-source>
	</div>
</template>
<script>
	export default {
		props: {
			searchPage: {
				type: String,
				default: ''
			},
			menuClass: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: ''
			},
			autoClose: {
				type: Boolean,
				default: true
			},
		},
		data: function() {
			return {
				totalRecords: 0,
				records: [],
				searchText: '',
				show: false,
				pagination: {
					page: 1,
					limit: 10,
				},
			}
		},
		computed: {
			apiPath () {
				let search = this.searchText;
				if(search){
					let query = {
						page: this.pagination.page,
						limit: this.pagination.limit,
						search
					};
					let qs = this.$utils.serializeQuery(query);
					return this.searchPage + "?" + qs;
				}
				return null;
			},
			recordsPosition(){
				return Math.min(this.pagination.page * this.pagination.limit, this.totalRecords);
			},
			totalPages(){
				if (this.totalRecords > this.pagination.limit) {
					return Math.ceil(this.totalRecords / this.pagination.limit);
				}
				return 1;
			}
		},
		methods: {
			debounceInput(event) {
  if (this.debounceTimeout) {
    clearTimeout(this.debounceTimeout);
  }

  this.debounceTimeout = setTimeout(() => {
    this.searchText = event.target.value;
  }, 1000);
},

			searchCompleted(response) {
				if (response.records){
					this.records = response.records;
					this.totalRecords = response.totalRecords;
				}
				else{
					this.records = [];
					this.totalRecords = 0;
				}
			},
			showResult () {
				this.show = this.searchText !== '';
				return this.show;
			},
			hideSearch () {
				this.show = false;
				this.searchText = '';
			},
		},
		watch: {
			searchText: function() {
                this.showResult();
			}
		},
	};
</script>