
export const AppMenus = {
    
	navbarTopRight: [
  {
    "to": "/complain/add",
    "label": "Support",
    "icon": "",
    "iconcolor": "",
    "target": "",
    
  }
],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/finds/adminfinds",
    "label": "Finds",
    "icon": "pi pi-id-card",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/finds/add",
    "label": "Upload",
    "icon": "pi pi-upload",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/complain",
    "label": "Complain",
    "icon": "pi pi-envelope",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/more",
    "label": "More",
    "icon": "pi pi-ellipsis-v",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/terms",
        "label": "Terms & Conditions",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/reminders",
        "label": "Reminders",
        "icon": "pi pi-bell",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/users",
        "label": "Users",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "pi pi-shield",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions",
    "label": "Permissions",
    "icon": "pi pi-key",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users",
    "label": "users",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/category",
    "label": "Categories",
    "icon": "pi pi-list",
    "iconcolor": "",
    "target": "",
    
  }
],
	status: [    
{value: "active", label: "Active"},
	{value: "collected", label: "Collected"}
    ],
	status2: [{"label":"Active","value":"active"},{"label":"Collected","value":"collected"}],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}